import React, { useCallback } from "react";
export const BaseImg = ({ src, alt, className, style, loading = "lazy", onError, onClick, srcset, sizes, }) => {
    const handleError = useCallback((event) => {
        if (typeof onError === "function") {
            onError(event);
            return;
        }
        const target = event.target;
        console.warn(`Could not load image with src ${target.src}!`);
    }, [onError]);
    if (!src) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("img", { loading: loading, src: src, alt: alt, className: className, style: style, onError: handleError, onClick: onClick, srcSet: srcset, sizes: sizes }));
};
