import React, { useState, useCallback, useEffect } from "react";
import NavDropdown from "./NavDropdown/NavDropdown";
import { Logo } from "../../Logo/Logo";
import { LoginButtons } from "./NavDropdown/loginAction/LoginButtons";
import { SecondaryNavigation } from "../SecondaryNavigation";
import { IconChevronDown, IconClose } from "../../svgIcons";
import { SearcherWidgets } from "../viewHeaders/SearcherWidgets";
import { usePageViewStyle } from "../../../hooks/index";
import NavDropdownHover from "./NavDropdown/NavDropdownHover";
export const Navigation = (props) => {
    const [dropdownData, setDropdownData] = useState();
    const [dropdownType, setDropdownType] = useState();
    const [activeMenu, setActiveMenu] = useState("");
    const [mouseOverDropdown, setMouseOverDropdown] = useState(false);
    const [mouseOverMenu, setMouseOverMenu] = useState(true);
    const { isLarge, isSmall, isMedium } = usePageViewStyle();
    useEffect(() => {
        if (!mouseOverDropdown && !mouseOverMenu) {
            setDropdownData(undefined);
            setActiveMenu("");
        }
    }, [mouseOverDropdown, mouseOverMenu]);
    const activeMenuHandler = (val, type) => useCallback((e) => {
        const body = document.getElementsByTagName("body")[0];
        e.preventDefault();
        setActiveMenu("");
        body.classList.remove("menu-active");
        body.classList.remove("search-active");
        setDropdownData(undefined);
        setMouseOverMenu(true);
        const triggerDropdown = () => setTimeout(() => {
            body.classList.add("menu-active");
            setActiveMenu(val);
            setDropdownData([...props.navigationItems].find((item) => item.title === val));
            setDropdownType(type);
        }, 200);
        if (document.querySelector(".navDropdown__widget.active")) {
            triggerDropdown();
        }
        if (val !== "" && val !== activeMenu) {
            document
                .querySelector(".navDropdown.active")
                ?.classList.remove("active");
            triggerDropdown();
        }
    }, [activeMenu, type, val]);
    const handleCloseDropDown = useCallback(() => {
        document.getElementsByTagName("body")[0].classList.remove("mobile-nav-active");
    }, []);
    const handleMouseOut = useCallback(() => {
        if (!isLarge) {
            return;
        }
        setMouseOverMenu(false);
    }, [isLarge]);
    const handleOnMenuActive = (menuitem) => activeMenuHandler(menuitem, undefined);
    return (React.createElement("div", { className: "navigation__container" },
        React.createElement(Logo, { logoHref: props.logoHref ? props.logoHref : "/", imageUrl: props.imageUrl ? props.imageUrl : undefined, imageUrlReverse: props.imageUrlReverse ? props.imageUrlReverse : undefined }),
        React.createElement("div", { className: "navigation__list" },
            React.createElement("div", { className: "navigation__menuWrapper" },
                React.createElement("div", { className: "navigation__menuTitleWrapper" },
                    React.createElement("p", { className: "navigation__menuTitleWrapper__menuTitle" }, props.menuTitle),
                    React.createElement("a", { onClick: handleCloseDropDown }, IconClose())),
                props.navigationItems.length > 0 &&
                    props.navigationItems.map((item, i) => item.type === "text" && (React.createElement("div", { key: `navigation-menu__item--${i}`, className: `navigation__item ${activeMenu === item.title
                            ? "navigation__item--current"
                            : ""}` },
                        React.createElement("span", { className: "navigation__link", onClick: (isSmall || isMedium) ? handleOnMenuActive(item.title) : undefined, onMouseLeave: handleMouseOut },
                            item.title,
                            IconChevronDown({ fill: "#0E1D31" })),
                        isLarge && React.createElement(NavDropdownHover, { click: activeMenuHandler("", undefined), dropdownType: item.type, data: item }))))),
            React.createElement(SecondaryNavigation, { data: props.data, widgets: props.widgets, click: activeMenuHandler("", undefined) })),
        React.createElement("div", { className: "loginAndSearchWrapper" },
            React.createElement(SearcherWidgets, { searchItems: props.searchWidgetProps.searchItems, searchPlaceholder: props.searchPlaceholder }),
            React.createElement(LoginButtons, { activeMenu: activeMenu, activeMenuHandler: activeMenuHandler, navigationItems: props.navigationItems })),
        React.createElement(NavDropdown, { click: activeMenuHandler("", undefined), dropdownType: dropdownType, data: dropdownData, mouseOver: setMouseOverDropdown })));
};
